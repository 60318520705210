export enum ATeamHcpStatus {
  A_TEAM = "A_TEAM",
  PRIORITY_ACCESS = "PRIORITY_ACCESS",
  OFF = "OFF",
}

export type ATeamWorkplaces = Record<
  string,
  { name: string; id: string; workerTypes: string[]; recentUpdatedAt?: Date }
>;
