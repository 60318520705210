import { isDefined } from "@clipboard-health/util-ts";

import { CbhFeatureFlag, FEATURE_FLAG_DEFAULT_VALUES, useCbhFlag } from "../FeatureFlags";
import { type GetWorkerRankingsQuery, useGetWorkerRankings } from "./api/useGetWorkerRankings";
import { ATeamHcpStatus } from "./types";
import { workerRankingsByWorkplaces } from "./utils";

export function useGetATeamWorkplaces() {
  const { status: aTeamStatus } = useCbhFlag(CbhFeatureFlag.A_TEAM_HCP, {
    defaultValue: FEATURE_FLAG_DEFAULT_VALUES[CbhFeatureFlag.A_TEAM_HCP],
  });
  const isWorkerAteamEnabled = [ATeamHcpStatus.A_TEAM, ATeamHcpStatus.PRIORITY_ACCESS].includes(
    aTeamStatus
  );

  const query: GetWorkerRankingsQuery = {
    include: ["workplace"],
    fields: {
      ranking: ["isOnATeam", "workerType", "updatedAt", "workplace.id"],
      workplace: ["name"],
    },
    filter: {
      isOnATeam: true,
    },
  };

  const {
    data: workerRankings,
    isLoading,
    refetch: refetchATeamWorkplaces,
  } = useGetWorkerRankings(query, {
    enabled: isWorkerAteamEnabled,
  });

  if (!isWorkerAteamEnabled) {
    return {
      aTeamWorkplaces: {},
      isWorkerAteamEnabled: false,
      aTeamStatus,
      aTeamWorkplacesCount: 0,
    };
  }

  const aTeamWorkplaces = isDefined(workerRankings)
    ? workerRankingsByWorkplaces(workerRankings)
    : {};

  return {
    aTeamWorkplaces,
    isWorkerAteamEnabled,
    aTeamStatus,
    aTeamWorkplacesCount: Object.keys(aTeamWorkplaces).length,
    isATeamWorkplaceLoading: isLoading,
    refetchATeamWorkplaces,
  };
}
